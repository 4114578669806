import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";
import { InventoryInterface } from "../interfaces";
import { InventoryConsumptionInterface } from "@products-services/ports/interfaces";


export abstract class InventoryAbstraction {
    abstract list(query: string): Observable<RespListInterface<InventoryInterface>>;
    abstract create(inventory: InventoryInterface): Observable<RespInterface<InventoryInterface>>;
    abstract update(inventory: InventoryInterface): Observable<RespInterface<InventoryInterface>>;
    abstract read(id: string): Observable<RespInterface<InventoryInterface>>;
    abstract delete(id: string): Observable<RespInterface<InventoryInterface>>;
    abstract actions(unsubscribe: any): Observable<RespInterface<any>>;
    abstract listInventoryUsage(query: string, id: string): Observable<RespListInterface<InventoryConsumptionInterface>>;
    abstract createInventoryUsage(inventoryUsage: InventoryConsumptionInterface): Observable<RespInterface<InventoryConsumptionInterface>>;
    abstract updateInventoryUsage(inventoryUsage: InventoryConsumptionInterface): Observable<RespInterface<InventoryConsumptionInterface>>;
    abstract deleteInventoryUsage(id: string): Observable<RespInterface<InventoryConsumptionInterface>>;
}